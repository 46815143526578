import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR } from '@/store'
import {
  KimSubjectIdGetRequest,
  QuestionPaginator,
  QuestionPaginatorRequest, QuestionPublishRequest,
  QuestionsPaginatorGetRequest,
  QuestionsQuestionIdDeleteRequest,
  QuestionsQuestionIdGetRequest,
  QuestionsQuestionIdPublishPostRequest,
  QuestionsStorePostRequest,
  QuestionStoreRequest,
  QuestionTypesGetRequest,
  SectionsGetParams,
  SectionsGetRequest,
  SourcesGetParams,
  SourcesGetRequest,
  StatusesGetRequest,
  SubjectResource,
  SubjectsGetRequest,
  ThemesGetParams,
  ThemesGetRequest,
} from '@/store/types/schema.training'

/**
 * Модуль для работы с разделом тренажера у менеджера
 * - todo
 */

@Module({
  dynamic: true,
  name: 'ManagerTraining',
  namespaced: true,
  store,
})
class ManagerTraining extends VuexModule {
  // ---------------------------- Subjects ---------------------------- >>
  subjects: SubjectResource[] = []

  @Mutation
  setSubjects(payload: SubjectResource[]) {
    this.subjects = [...payload]
  }

  @Action({ rawError: true })
  async fetchSubjects() {
    const { data } = await SubjectsGetRequest()
    this.setSubjects(data)
    return data
  }

  // ---------------------------- Filter ---------------------------- >>
  filter: QuestionPaginatorRequest = {
    page: 1,
    perPage: 10,
    subjectId: 1,
  }

  @Mutation
  setFilter(payload: QuestionPaginatorRequest) {
    this.filter = Object.assign({}, payload)
  }

  @Mutation
  updateFilter(payload: QuestionPaginatorRequest) {
    this.filter = {
      ...this.filter,
      ...payload,
    }
  }

  // ---------------------------- List ---------------------------- >>
  questions: QuestionPaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setQuestions(payload: QuestionPaginator) {
    this.questions = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchQuestionsTraining(filter: QuestionPaginatorRequest) {
    const { data } = await QuestionsPaginatorGetRequest(filter)
    this.setQuestions(data)
    return data
  }

  // ---------------------------- KIMs ---------------------------- >>
  @Action({ rawError: true })
  async fetchKIM(subjectID: number) {
    const { data } = await KimSubjectIdGetRequest(subjectID)
    return data
  }

  // ---------------------------- Sections ---------------------------- >>
  @Action({ rawError: true })
  async fetchSections(payload: SectionsGetParams) {
    const { data } = await SectionsGetRequest(payload)
    return data
  }

  // ---------------------------- Themes ---------------------------- >>
  @Action({ rawError: true })
  async fetchThemes(payload: ThemesGetParams) {
    const { data } = await ThemesGetRequest(payload)
    return data
  }

  // ---------------------------- Sources ---------------------------- >>
  @Action({ rawError: true })
  async fetchSources(payload: SourcesGetParams) {
    const { data } = await SourcesGetRequest(payload)
    return data
  }

  // ---------------------------- Statuses ---------------------------- >>
  @Action({ rawError: true })
  async fetchStatuses() {
    const { data } = await StatusesGetRequest()
    return data
  }

  // ---------------------------- Question Types ---------------------------- >>
  @Action({ rawError: true })
  async fetchQuestionTypes() {
    const { data } = await QuestionTypesGetRequest()
    return data
  }

  // ---------------------------- Item ---------------------------- >>
  @Action({ rawError: true })
  async fetchQuestion(questionID: number) {
    const { data } = await QuestionsQuestionIdGetRequest(questionID)
    return data
  }

  @Action({ rawError: true })
  async deleteQuestion(payload: { questionID: number, options?: any }) {
    const { data } = await QuestionsQuestionIdDeleteRequest(payload.questionID, payload.options)
    return data
  }

  @Action({ rawError: true })
  async saveQuestion(payload: QuestionStoreRequest) {
    const { data } = await QuestionsStorePostRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async publishQuestion(payload: { body: QuestionPublishRequest, questionID: number }) {
    const { data } = await QuestionsQuestionIdPublishPostRequest(payload.questionID, payload.body)
    return data
  }
}

const ManagerTrainingModule = getModule(ManagerTraining)

export default ManagerTrainingModule
